import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "./Redux/Slice/languageSlice";

// Import Style
import "./App.css";

// Import Pages
import NotFound from "./Pages/NotFound";
import Layout from "./Pages/Layout";

function App() {
  const isError = useSelector((state) => state.isError.isError);
  const selectLang = useSelector((state) => state.language.value);

  const dispatch = useDispatch();

  useEffect(() => {
    setInterval(() => {
      const value = document.querySelector("html").getAttribute("lang");
      if (value === selectLang) {
        return true;
      } else {
        dispatch(setLanguage(value));
      }
    }, 1000);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout path="/" />,
    },
    {
      path: "/track",
      element: <Layout path="/track" />,
    },
    {
      path: "/track/:tracking_number",
      element: <Layout path="/track/:tracking_number" />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  const error = createBrowserRouter([
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  if (isError) {
    return <RouterProvider router={error} />;
  }

  return <RouterProvider router={router} />;
}

export default App;
