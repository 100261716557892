import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Style
import "./style.css";

// File
import Header from "../../Component/Header";
import Home from "../Home";
import Tracking from "../Tracking";

// Loder
import Loder from "../Loader";

import { getSetting } from "./../../API/getSetting";
import { setLoader } from "../../Redux/Slice/loaderSlice";
import { setTheme } from "../../Redux/Slice/configSlice";
import HTML_Header from "../../HTML/Header";

export default function Layout({ path }) {
  const configuration = useSelector((state) => state.configuration.theme);
  const loader = useSelector((state) => state.loader.loader);

  const dispatch = useDispatch();

  const { getSiteData } = getSetting();

  useEffect(() => {
    getSiteSetting();
  }, []);

  function getDomainName(url) {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.hostname
        .replace("www.", "")
        .replace("https://", "")
        .replace("http://", "")
        .replace(".trackship.com", "")
        .replace(".trackship.app", "");
    } catch (error) {
      console.error("Error parsing URL:", error);
      return null;
    }
  }

  const defaultValue = {
    store_name: "",
    icon_value: require("./../../Assets/Logo/ts_fevicon.png"),
    logo_value: require("./../../Assets/Logo/ts_w.png"),
    contact_url: "https://trackship.com/contact",
    fb_url: "",
    tw_url: "",
    in_url: "",
    ln_url: "",
    tk_url: "",
    pt_url: "",
    tracking_page: window.location.origin,
    default_language: "en",
    analytics_id: "",
    custom_domain: "",
    logo_align: "Left",
    header_bg_color: "#3c4758",
    tracker_type: "default",
    display_branding: "enable",
    provider_logo: "enable",
    enable_tracking_link: "enable",
    display_from_to: "enable",
    display_delivery_number: "enable",
    content_bg_color: "#ffffff",
    border_color: "#e0e0e0",
    font_color: "#333",
    link_color: "#0000ee",
    footer_bg_color: "",
    display_social_link: "enable",
    form_heading_text: "Track Shipment",
    form_description: "",
    form_button_text: "Track",
    button_text_color: "#ffffff",
    button_color: "#3c4758",
    footer_links: "",
  };

  const getSiteSetting = async () => {
    if (
      getDomainName(window.location.origin) === "track" ||
      window.location.host === "localhost:3000"
    ) {
      // setCookie("googtrans", "/en/en", 1);
      dispatch(setLoader(false));
      dispatch(setTheme(defaultValue));
      return false;
    } else {
      const localStorage = window.localStorage.getItem("tracking_setting");
      if (localStorage) {
        dispatch(setTheme(JSON.parse(localStorage)));
        dispatch(setLoader(false));
      } else {
        dispatch(setLoader(true));
      }
    }

    try {
      const body = {
        store_name: getDomainName(window.location.origin),
      };
      const data = await getSiteData(body, dispatch);
      if (data && Object.keys(data).length > 0) {
        const configurationCopy = { ...defaultValue };
        Object.keys(data).map((key, index) => {
          if (data[key]) {
            configurationCopy[key] = data[key];
          }
        });
        dispatch(setTheme(configurationCopy));
        window.localStorage.setItem(
          "tracking_setting",
          JSON.stringify(configurationCopy)
        );
        // setCookie("googtrans", "/en/" + configurationCopy.default_language, 1);
      } else {
        dispatch(setTheme(defaultValue));
        window.localStorage.setItem(
          "tracking_setting",
          JSON.stringify(defaultValue)
        );
      }
      dispatch(setLoader(false));
    } catch (error) {
      dispatch(setLoader(false));
    }
  };

  // function setCookie(key, value, expiry) {
  //   var expires = new Date();
  //   expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
  //   console.log(key + "=" + value + ";expires=" + expires.toUTCString());
  //   document.cookie = key + "=" + value + ";expires=" + expires.toUTCString();
  // }

  const pages = {
    "/": <Home />,
    "/track": <Home isTracking />,
    "/track/:tracking_number": <Tracking />,
  };

  if (loader) {
    return (
      <div>
        <Loder />
      </div>
    );
  } else if (Object.keys(configuration).length !== 0) {
    return (
      <div>
        <HTML_Header configuration={configuration} />
        <Header
          tracking_url={configuration.tracking_page}
          header_logo={configuration.logo_value}
          bg_color={configuration.header_bg_color}
        />
        {pages[path]}
      </div>
    );
  }
}
