/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Style
import "./style.css";

import Title from "../../Component/Title";
import FooterLink from "../../Component/FooterLink";
import Branding from "../../Component/Branding";
import SocialLink from "../../Component/SocialLink";

import { getTracking } from "../../API/getTracking";
import { setTracking } from "../../Redux/Slice/trackingSlice";

export default function Tracking() {
  const configuration = useSelector((state) => state.configuration.theme);
  const trackingDetails = useSelector((state) => state.tracking.tracking);
  const selectLang = useSelector((state) => state.language.value);

  const dispatch = useDispatch();

  let { tracking_number } = useParams();

  const contentRef = useRef(null);
  const detailsRef = useRef(null);

  const { getTrackingData } = getTracking();

  const [selectedTab, setselectedTab] = useState("overview");
  const [loading, setloading] = useState(true);
  const [detailsHeight, setdetailsHeight] = useState(0);
  const [detailCollapse, setdetailCollapse] = useState(false);

  const [height, setHeight] = useState("0px");

  useEffect(() => {
    if (contentRef.current) {
      if (selectedTab === "journey") {
        setHeight(`${contentRef.current.scrollHeight}px`);
      } else {
        setHeight("0px");
      }
    }
  }, [selectedTab]);

  const handleTransitionEnd = () => {
    if (selectedTab === "journey") {
      setHeight("auto");
    }
  };

  const toggleCollapse = (type) => {
    if (type === "overview") {
      setHeight(`${contentRef.current.scrollHeight}px`);
    }
    setselectedTab(type);
  };

  useEffect(() => {
    getTrackingDetails();
  }, []);

  useEffect(() => {
    if (detailsRef.current) {
      setdetailsHeight(detailsRef.current.scrollHeight - 1);
    }
  }, [detailCollapse]);

  const getTrackingDetails = async () => {
    setloading(true);
    try {
      const body = {
        tracking_number: tracking_number,
      };
      const { data } = await getTrackingData(body);
      if (data) {
        dispatch(setTracking(data));
      } else {
        dispatch(setTracking({}));
      }
      setloading(false);
    } catch (error) {
      dispatch(setTracking({}));
      setloading(false);
    }
  };

  const getEventMessage = (event) => {
    if (event) {
      let message = "";
      message += event.message;
      message +=
        Object.keys(event.tracking_location).length > 0 &&
        event.tracking_location.city
          ? ` - ${event.tracking_location.city}`
          : "";
      return message;
    }
  };

  function copyText() {
    var copyText = document.getElementById("copy_text");
    var textArea = document.createElement("textarea");
    textArea.value = copyText.textContent;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("Copy");
    textArea.remove();
    toast.success("Tracking Number Copied!");
  }

  return (
    <>
      {!loading && (
        <Title
          title_text={configuration.form_heading_text}
          text_color={configuration.font_color}
        />
      )}
      <div className="tracking-container">
        {loading ? (
          <div
            style={{
              padding: "150px 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={require("./../../Assets/Icons/loader.gif")} alt="" />
          </div>
        ) : Object.keys(trackingDetails).length > 0 ? (
          <div
            style={{
              borderColor: configuration.border_color,
              backgroundColor: configuration.content_bg_color,
            }}
            className="tracking-main-box"
          >
            <div
              style={{
                borderColor: configuration.border_color,
              }}
              className="provider-tracking-number-container"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {configuration.provider_logo === "enable" && (
                  <img
                    className="provider-logo"
                    src={`https://api.trackship.com/v1/shipping-carrier-image.png?slug=${trackingDetails.tracking_provider}`}
                    alt="logo"
                  />
                )}
                <div
                  style={{
                    marginLeft:
                      configuration.provider_logo === "disable" ? 0 : 15,
                    marginRight: 15,
                  }}
                  className="provider-tracking-number"
                >
                  <div
                    style={{ color: configuration.font_color }}
                    className="provider-name"
                  >
                    {trackingDetails.shipping_provider
                      ? trackingDetails.shipping_provider
                      : trackingDetails.tracking_provider}
                  </div>
                  <div style={{ display: "flex", marginTop: 4 }}>
                    <div
                      id="copy_text"
                      style={{ color: configuration.font_color }}
                      className="tracking-number"
                    >
                      {trackingDetails.tracking_code}
                    </div>
                    <div
                      onClick={() => {
                        copyText();
                      }}
                      style={{
                        color: configuration.link_color,
                        cursor: "pointer",
                        margin: "0 5px",
                      }}
                    >
                      <i style={{ fontSize: 14 }} className="far fa-copy"></i>
                    </div>
                  </div>
                </div>
              </div>
              {configuration.enable_tracking_link === "enable" && (
                <a
                  rel="noreferrer"
                  href={trackingDetails.tracking_url
                    .replace("%number%", trackingDetails.tracking_code ?? "")
                    .replace(
                      "%country_code%",
                      trackingDetails.destination_country ?? ""
                    )
                    .replace(
                      "%postal_code%",
                      trackingDetails.postal_code ?? ""
                    )}
                  target="_blank"
                  style={{
                    color: configuration.link_color,
                    textDecoration: "none",
                    marginLeft: 10,
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="go_to_carrier_website_button"
                >
                  <div style={{ fontSize: 14 }}>Go to carrier Website</div>
                  <i
                    style={{ fontSize: 13, margin: "0 5px" }}
                    className="fas fa-external-link-alt"
                  ></i>
                </a>
              )}
            </div>
            <div
              className="date_and_button"
              style={{
                padding:
                  trackingDetails.est_delivery_date ||
                  trackingDetails.status === "delivered" ||
                  (trackingDetails.events && trackingDetails.events.length > 1)
                    ? "20px 20px 0 20px"
                    : 0,
              }}
            >
              {trackingDetails.est_delivery_date ||
              trackingDetails.status === "delivered" ? (
                <span
                  style={{
                    color: configuration.font_color,
                  }}
                  className={"est-delivery-date " + trackingDetails.status}
                >
                  {trackingDetails.status === "delivered"
                    ? "Delivered on: "
                    : "Estimated delivery: "}
                  <strong>
                    {moment(
                      trackingDetails.status === "delivered" &&
                        trackingDetails.events.length > 0
                        ? [...trackingDetails.events].reverse()[0].datetime
                        : trackingDetails.est_delivery_date
                    ).format("dddd, MMM DD")}
                  </strong>
                </span>
              ) : null}
              {trackingDetails.events &&
              trackingDetails.events.length > 1 &&
              (trackingDetails.est_delivery_date ||
                trackingDetails.status === "delivered") ? (
                <div style={{}} className="btn-view">
                  <div
                    onClick={() => toggleCollapse("overview")}
                    style={{
                      background:
                        selectedTab === "overview" ? "#ffffff" : "transparent",
                      boxShadow:
                        selectedTab === "overview"
                          ? "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em"
                          : "",
                    }}
                    className="btn-view-text"
                  >
                    Overview
                  </div>
                  <div
                    onClick={() => toggleCollapse("journey")}
                    style={{
                      background:
                        selectedTab === "journey" ? "#ffffff" : "transparent",
                      boxShadow:
                        selectedTab === "journey"
                          ? "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em"
                          : "",
                    }}
                    className="btn-view-text"
                  >
                    Journey
                  </div>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderColor: configuration.border_color }}
              className={`tracking_widget_tracking_events_section`}
            >
              {trackingDetails.events &&
                trackingDetails.events.length > 1 &&
                !trackingDetails.est_delivery_date &&
                trackingDetails.status !== "delivered" && (
                  <div
                    style={{ float: "right", position: "relative", zIndex: 2 }}
                    className="btn-view date_and_button_section"
                  >
                    <div
                      onClick={() => toggleCollapse("overview")}
                      style={{
                        background:
                          selectedTab === "overview"
                            ? "#ffffff"
                            : "transparent",
                        boxShadow:
                          selectedTab === "overview"
                            ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                            : "",
                      }}
                      className="btn-view-text"
                    >
                      Overview
                    </div>
                    <div
                      onClick={() => toggleCollapse("journey")}
                      style={{
                        background:
                          selectedTab === "journey" ? "#ffffff" : "transparent",
                        boxShadow:
                          selectedTab === "journey"
                            ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                            : "",
                      }}
                      className="btn-view-text"
                    >
                      Journey
                    </div>
                  </div>
                )}
              <div className="enhanced_overview enhanced_tracking_details enhanced_overview_1">
                <div
                  className={`heading_shipment_status ${trackingDetails.status}`}
                >
                  {{
                    pre_transit: "Pre Transit",
                    in_transit: "In Transit",
                    available_for_pickup: "Available for pickup",
                    out_for_delivery: "Out for delivery",
                    delivered: "Delivered",
                    return_to_sender: "Return to sender",
                    failure: "Delivery Failure",
                    exception: "Exception",
                    on_hold: "On Hold",
                  }[trackingDetails.status] ?? "Shipped"}
                </div>
                <div
                  style={{ paddingTop: 10 }}
                  className={`tracking_detail shipped ${trackingDetails.status}`}
                >
                  <strong style={{ color: configuration.font_color }}>
                    {trackingDetails.events &&
                      trackingDetails.events.length > 0 &&
                      moment(
                        [...trackingDetails.events].reverse()[0].datetime
                      ).format("MMMM Do YYYY, h:mm a")}
                  </strong>
                  <div style={{ color: configuration.font_color }}>
                    {trackingDetails.events && trackingDetails.events.length > 0
                      ? getEventMessage(
                          [...trackingDetails.events].reverse()[0]
                        )
                      : "Tracking is still not yet available for this shipment, please try again later."}
                  </div>
                </div>
              </div>
              <div
                ref={contentRef}
                key={selectLang}
                className={`enhanced_journey`}
                style={{
                  height,
                }}
                onTransitionEnd={handleTransitionEnd}
              >
                {trackingDetails.events &&
                  trackingDetails.events.length > 0 &&
                  [...trackingDetails.events].reverse().map((item, index) => {
                    if (index === 0) {
                      return null;
                    }
                    return (
                      <div className="tracking_detail">
                        <strong style={{ color: configuration.font_color }}>
                          {moment(item.datetime).format("MMMM Do YYYY, h:mm a")}
                        </strong>
                        <div style={{ color: configuration.font_color }}>
                          {getEventMessage(item)}
                        </div>
                      </div>
                    );
                  })}
                {trackingDetails.destination_events &&
                  trackingDetails.destination_events.length > 0 && (
                    <h4
                      style={{ color: configuration.font_color }}
                      className="origin_details_title"
                    >
                      Origin Details
                    </h4>
                  )}
                {trackingDetails.destination_events &&
                  trackingDetails.destination_events.length > 0 &&
                  [...trackingDetails.destination_events]
                    .reverse()
                    .map((item, index) => {
                      return (
                        <div className="tracking_detail">
                          <strong style={{ color: configuration.font_color }}>
                            {moment(item.datetime).format(
                              "MMMM Do YYYY, h:mm a"
                            )}
                          </strong>
                          <div style={{ color: configuration.font_color }}>
                            {getEventMessage(item)}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div
              onClick={() => {
                setdetailCollapse(!detailCollapse);
              }}
              className="tracking_detail_main"
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  fontSize: 15,
                  color: configuration.font_color,
                  fontWeight: "600",
                }}
              >
                Shipment Details
              </div>
              <i
                style={{
                  fontSize: 15,
                  transform: `rotate(${detailCollapse ? 90 : 0}deg)`,
                  color: configuration.font_color,
                }}
                className="fas fa-chevron-right"
              ></i>
            </div>
            <div
              ref={detailsRef}
              className={`enhanced_journey`}
              style={{
                height: detailCollapse ? detailsHeight : 0,
              }}
            >
              <div
                style={{
                  borderTop: "1px solid " + configuration.border_color,
                }}
              >
                {trackingDetails.store_url ? (
                  <div
                    style={{
                      margin: "0 10px",
                      padding: "15px 10px",
                      borderBottom: "1px solid " + configuration.border_color,
                      color: configuration.font_color,
                      fontSize: 14,
                    }}
                  >
                    Store:{" "}
                    <a
                      href={trackingDetails.store_url}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: configuration.link_color,
                        textDecoration: "none",
                        fontWeight: "600",
                      }}
                    >
                      {trackingDetails.store_url}
                    </a>
                  </div>
                ) : null}
                <div
                  className="tracking_detail_section"
                  style={{ padding: "10px 0" }}
                >
                  {trackingDetails.order_number || trackingDetails.order_id ? (
                    <div
                      style={{
                        margin: "0 10px",
                        padding: "5px 10px",
                        // borderBottom: "1px solid #f5f5f5",
                        color: configuration.font_color,
                        fontSize: 14,
                      }}
                    >
                      Order:{" "}
                      <span
                        style={{
                          color: configuration.text_color,
                          fontWeight: "600",
                        }}
                      >
                        #
                        {trackingDetails.order_number
                          ? trackingDetails.order_number
                          : trackingDetails.order_id}
                      </span>
                    </div>
                  ) : null}
                  {trackingDetails.shipment_items &&
                  JSON.parse(trackingDetails.shipment_items).length > 0 ? (
                    <div
                      style={{
                        margin: "0 10px",
                        padding: "5px 10px",
                        fontSize: 14,
                        // borderBottom: "1px solid #f5f5f5",
                      }}
                    >
                      Shipped items:
                      {JSON.parse(trackingDetails.shipment_items).map(
                        ({ name, qty }, index) => {
                          if (!name) return false;
                          return (
                            <div
                              style={{
                                marginTop: index === 0 ? 5 : 0,
                                color: configuration.font_color,
                              }}
                            >
                              - {name} x {qty}
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : null}
                  {configuration.display_from_to === "enable" &&
                  trackingDetails.origin_country &&
                  trackingDetails.destination_country &&
                  trackingDetails.origin_country !==
                    trackingDetails.destination_country ? (
                    <div
                      style={{
                        margin: "0 10px",
                        padding: "5px 10px",
                        color: configuration.font_color,
                        // borderBottom: "1px solid #f5f5f5",
                        fontSize: 14,
                      }}
                    >
                      {`Shipped from `}
                      <span style={{ fontWeight: "600" }}>
                        {trackingDetails.origin_country}
                      </span>
                      {` to the `}
                      <span style={{ fontWeight: "600" }}>
                        {trackingDetails.destination_country}
                      </span>
                    </div>
                  ) : null}
                  {configuration.display_delivery_number === "enable" &&
                  trackingDetails.delivery_number ? (
                    <div
                      style={{
                        margin: "0 10px",
                        padding: "5px 10px",
                        // borderBottom: "1px solid #f5f5f5",
                        color: configuration.font_color,
                        fontSize: 14,
                      }}
                    >
                      Delivery Tracking Number:{" "}
                      <a
                        href={"javascript:void(0)"}
                        style={{
                          color: configuration.link_color,
                          textDecoration: "none",
                          fontWeight: "600",
                        }}
                      >
                        {trackingDetails.delivery_number}
                      </a>
                    </div>
                  ) : null}
                  {trackingDetails.shipping_service ? (
                    <div
                      style={{
                        margin: "0 10px",
                        padding: "5px 10px",
                        // borderBottom: "1px solid #f5f5f5",
                        color: configuration.font_color,
                        fontSize: 14,
                      }}
                    >
                      Shipping Service:{" "}
                      <span
                        style={{
                          color: configuration.text_color,
                          fontWeight: "600",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: trackingDetails.shipping_service,
                        }}
                      ></span>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              maxWidth: 800,
              margin: "auto",
              border: "1px solid red",
              padding: 20,
              background: configuration.content_bg_color,
              borderRadius: 10,
              textAlign: "center",
            }}
          >
            <span
              style={{
                color: configuration.font_color,
              }}
            >
              Sorry! Tracking number{" "}
              <span
                style={{ color: configuration.link_color, fontWeight: "600" }}
              >
                {tracking_number}
              </span>{" "}
              is not found in TrackShip
            </span>
          </div>
        )}
        <div>
          <FooterLink configuration={configuration} />
          {configuration.display_social_link === "enable" && (
            <SocialLink configuration={configuration} />
          )}
          {configuration.display_branding === "disable" ? null : <Branding />}
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
